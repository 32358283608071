import React from "react";
import "./index.scss";

const FlexHorizontal = ({ children, center, padding, flow, ...props }) => {
  const style = center
    ? { justifyContent: "center" }
    : { justifyContent: "space-between" };

  style.padding = `${padding}px 0`;
  if (flow) style.flexFlow = "row wrap";

  return (
    <div {...props} style={style} className="flex-horizontal">
      {children}
    </div>
  );
};

FlexHorizontal.defaultProps = {
  children: null,
  center: null,
  flow: false,
  padding: 0,
};

export default FlexHorizontal;
