import React from "react";
import "./index.scss";

const FlexVertical = ({ children, grow, small, ...props }) => {
  const style = grow ? { flexGrow: 1 } : {};
  style.margin = small ? "6px 0" : "24px 0";

  return (
    <div {...props} style={style} className="flex-vertical">
      {children}
    </div>
  );
};

FlexVertical.defaultProps = {
  children: null,
  grow: null,
  small: false,
};

export default FlexVertical;
