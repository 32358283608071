import React, { useState } from "react";
import options from "../../data/menu.json";
import "./index.scss";

const Menu = ({ step }) => {
  const [toggle, setToggle] = useState(false);

  return (
    <>
      <div
        onClick={() => setToggle(!toggle)}
        className={`hamburger${toggle ? " open" : ""}`}
      >
        <div></div>
        <div></div>
        <div></div>
      </div>
      <nav
        className={`main${toggle ? " open" : ""}${step > 0 ? " push-up" : ""}`}
      >
        {options.map((option, idx) => (
          <a
            key={idx}
            className={idx === step ? "selected" : ""}
            href={option.href}
            onClick={() => setToggle(false)}
          >
            {option.title}
          </a>
        ))}
      </nav>
    </>
  );
};

export default Menu;
