/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState } from "react";
import { elementInViewport } from "../helpers/viewport";
import Header from "../components/header";
import Menu from "../components/menu";
import Wrapper from "../components/wrapper";
import HeaderBlock from "../components/header-block";
import FlexHorizontal from "../components/flex-horizontal";
import FlexVertical from "../components/flex-vertical";
import Project from "../components/project";
import faceBookIcon from "../resources/images/iconmonstr-facebook-3.svg";
import linkedInIcon from "../resources/images/iconmonstr-linkedin-3.svg";
import { projects } from "../data/projects";
import profileData from "../data/profile.json";
import experiences from "../data/experiences.json";
import skills from "../data/skills.json";

import "./index.scss";

const HomePage = () => {
  const [currentStep, setCurrentStep] = useState(0);
  let stepChange = null;
  window.addEventListener("scroll", () => {
    let step = null;
    if (elementInViewport("profile")) step = 0;
    else if (elementInViewport("experiences")) step = 1;
    else if (elementInViewport("skills")) step = 2;
    else if (elementInViewport("projects")) step = 3;
    else if (elementInViewport("anchor5")) step = 4;

    if (step !== null && step !== currentStep && stepChange !== step) {
      stepChange = step;
      setCurrentStep(step);
    }
  });
  return (
    <>
      <a className="profile" name="profile"></a>
      <Header />
      <Menu step={currentStep} />
      <Wrapper padding={24}>
        <HeaderBlock title={profileData.title}>
          {profileData.subTitle}
        </HeaderBlock>
        <FlexHorizontal>
          <FlexVertical>
            <h3>Details</h3>
            <b>Name:</b>
            <span>{profileData.name}</span>
            <p>&nbsp;</p>
            <b>Location:</b>
            <p>{profileData.Location}</p>
          </FlexVertical>
          <FlexVertical>
            <h3 className="indented">About me</h3>
            <p className="indented">{profileData.description}</p>
          </FlexVertical>
        </FlexHorizontal>
      </Wrapper>
      <a className="experiences" name="experiences"></a>
      <Wrapper dark padding={24}>
        <HeaderBlock title={experiences.title}>
          {experiences.subTitle}
        </HeaderBlock>
        {experiences.items.map((e, idx) => (
          <FlexHorizontal key={idx}>
            <FlexVertical>
              <div className="exp-dates">
                <b>
                  {e.dateFrom} &gt; {e.dateTo}
                </b>
              </div>
            </FlexVertical>
            <FlexVertical grow>
              <div className="right-block">
                <b>{e.companyName}</b>
                <p>{e.description}</p>
              </div>
            </FlexVertical>
          </FlexHorizontal>
        ))}
      </Wrapper>
      <a className="skills" name="skills"></a>
      <Wrapper padding={24}>
        <HeaderBlock title="Skills">Some of the skills I have.</HeaderBlock>
        {skills.map((skill, idx) => (
          <FlexHorizontal key={idx}>
            <FlexVertical small grow>
              <FlexHorizontal center>
                <div className="skill">{skill[0]}</div>
              </FlexHorizontal>
            </FlexVertical>
            <FlexVertical small grow>
              <FlexHorizontal center>
                <div className="skill">{skill[1]}</div>
              </FlexHorizontal>
            </FlexVertical>
          </FlexHorizontal>
        ))}
      </Wrapper>
      <a className="projects" name="projects"></a>
      <Wrapper dark padding={24}>
        <HeaderBlock title="Example Projects">
          Some example projects of my portfolio!
        </HeaderBlock>

        <FlexHorizontal flow>
          {projects.map((x, idx) => (
            <Project key={idx} image={x.bg} title={x.title} href={x.href}>
              {x.description}
            </Project>
          ))}
        </FlexHorizontal>
      </Wrapper>

      <Wrapper>
        <HeaderBlock title="Hobby Project">
          <a
            className="dark-link"
            href="https://attilaolbrich.co.uk/hobby-projects/zx-spectrum-sprite-editor"
          >
            Sinclair ZX-Spectrum Sprite Editor (React Js)
          </a>
        </HeaderBlock>
      </Wrapper>

      <a className="anchor5" name="anchor5"></a>
      <Wrapper footer padding={24}>
        <HeaderBlock fontColor="white" title="Contact Me">
          You can contact me on one of the following social channel
        </HeaderBlock>
        <FlexHorizontal>
          <FlexVertical grow>
            <FlexHorizontal center>
              <div className="social">
                <a href="https://www.linkedin.com/in/olbrich-attila-ba32b33b/">
                  <img src={linkedInIcon} alt="LinkedIn" /> LinkedIn
                </a>
              </div>
            </FlexHorizontal>
          </FlexVertical>
          <FlexVertical grow>
            <FlexHorizontal center>
              <div className="social">
                <a href="https://www.facebook.com/olbrichattila">
                  <img src={faceBookIcon} alt="Facebook" /> Facebook
                </a>
              </div>
            </FlexHorizontal>
          </FlexVertical>
        </FlexHorizontal>
      </Wrapper>
    </>
  );
};

export default HomePage;
