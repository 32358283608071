export const elementInViewport = (elementName) => {
  const el = document.querySelector(`.${elementName}`);

  if (!el) {
    return false;
  }

  const top = el.offsetTop;

  return (
    top >= window.pageYOffset && top <= window.pageYOffset + window.innerHeight
  );
};
