import React from "react";
import Logo from "../logo";
import ArrowDown from "../arrow-down";
import "./index.scss";

const Header = () => {
  return (
    <header>
      <div className="head-container">
        <Logo />
      </div>
      <div className="head-container middle">
        <h1>Attila Olbrich</h1>
        <hr />
        <span>Your Creative Web Developer and Online Marketing Consultant</span>
      </div>
      <div className="head-container right">
        <ArrowDown />
      </div>
    </header>
  );
};

export default Header;
