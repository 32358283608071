import hunafood from "../resources/images/refs/hunafood.png";
import beauty from "../resources/images/refs/beauty.png";
import dentist from "../resources/images/refs/dentist.png";
import electric from "../resources/images/refs/electric.png";
import vet from "../resources/images/refs/vet.png";
import bms4u from "../resources/images/refs/bms4u.png";
import domestic from "../resources/images/refs/domestic.png";
import garage from "../resources/images/refs/garage.png";
import photo from "../resources/images/refs/photo.png";
import video from "../resources/images/refs/video.png";
import allinwebshop from "../resources/images/refs/allinwebshop.png";
import orlon from "../resources/images/refs/orlon.png";

export const projects = [
  {
    bg: hunafood,
    title: "Hungarian food WebShop",
    description:
      "Various hungarian foods in UK, the WebShip targeting the Hungarian expats, and food lovers.",
    href: "https://www.hunafood.co.uk/en",
  },
  {
    bg: bms4u,
    title: "Booking Made Simple for you ",
    description: "This is a landing page for various bookig systems",
    href: "https://bms4u.com/",
  },
  {
    bg: beauty,
    title: "Beuty Salon",
    description: "Booking system for Beauty Salon (still uneder development",
    href: "https://demo.bms4u.com/nail-and-beauty-en",
  },
  {
    bg: dentist,
    title: "Dentist",
    description: "Booking system for Dentists(still uneder development",
    href: "https://demo.bms4u.com/dentist-en",
  },
  {
    bg: electric,
    title: "Electric Service",
    description:
      "Booking system for Electric Service providers (still uneder development",
    href: "https://demo.bms4u.com/dentist-en",
  },
  {
    bg: vet,
    title: "Vet",
    description: "Booking system for VET's",
    href: "https://demo.bms4u.com/vet-en",
  },
  {
    bg: domestic,
    title: "Domestic Assistance",
    description: "Booking System for Domestic Assistance",
    href: "https://demo.bms4u.com/domestic-assistance-en",
  },
  {
    bg: garage,
    title: "Garage",
    description: "Booking System for car services (garages)",
    href: "https://demo.bms4u.com/garage-en",
  },
  {
    bg: photo,
    title: "Photo Saloon",
    description: "Booking System for Photo Saloon",
    href: "https://demo.bms4u.com/photo-en",
  },
  {
    bg: video,
    title: "Video Making",
    description:
      "Bookig system for video shooting companies, (weddings, events)",
    href: "https://demo.bms4u.com/video-en",
  },
  {
    bg: allinwebshop,
    title: "All in WebShop",
    description:
      "SaaS WebShop with Stripe payment, running on Kubernetes service",
    href: "https://allinwebshop.com/",
  },
  {
    bg: orlon,
    title: "Orlon Solutions Ltd.",
    description: "Corporate Digital Agency website",
    href: "https://orlonsolutions.co.uk",
  },
];
