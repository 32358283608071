import React from "react";
import "./index.scss";

const HeaderBlock = ({ title, children, fontColor }) => {
  const style = fontColor ? { color: fontColor } : {};
  return (
    <div className="header-block">
      <h2 style={style}>{title}</h2>
      <p style={style}>{children}</p>
    </div>
  );
};

HeaderBlock.defaultProps = {
  children: null,
  title: null,
  fontColor: false,
};
export default HeaderBlock;
