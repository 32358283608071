import React from "react";
import { canHandleWebP } from "../../helpers/browserDetection";
import "./index.scss";

const Project = ({ children, title, image, href }) => {
  let convertedImage = null;
  if (image) {
    convertedImage = canHandleWebP()
      ? image.replace(/\.[^.]+$/, ".webp")
      : image;
  }

  const style = image ? { backgroundImage: `url(${convertedImage})` } : {};
  const Wrapper = href
    ? ({ children }) => (
        <a href={href} className="project" style={style}>
          {children}
        </a>
      )
    : ({ children }) => (
        <div className="project" style={style}>
          {children}
        </div>
      );

  return (
    <Wrapper>
      <div className="overlay"></div>
      <div className="container">
        <div>
          <h3>{title}</h3>
          <p>{children}</p>
        </div>
      </div>
    </Wrapper>
  );
};

Project.defaultProps = {
  title: null,
  children: null,
  href: "",
};

export default Project;
