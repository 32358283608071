import React from "react";
import "./index.scss";

const Wrapper = ({ children, dark, footer, padding }) => {
  const style = dark
    ? { backgroundColor: "#f3efe0" }
    : { backgroundColor: "#ffffff" };

  if (footer) {
    style.backgroundColor = "#434242";
    style.color = "#ffffff";
  }

  style.padding = `${padding}px 0`;

  return (
    <div style={style} className="wrapper">
      <div className="container">{children}</div>
    </div>
  );
};

Wrapper.defaultProps = {
  children: null,
  dark: false,
  footer: false,
  padding: 0,
};
export default Wrapper;
