import React from "react";
import { canHandleWebP } from "../../helpers/browserDetection";
import "./index.scss";
import logoPicture from "../../resources/images/profile-picture.png";

const Logo = () => {
  const convertedImage = canHandleWebP()
    ? logoPicture.replace(/\.[^.]+$/, ".webp")
    : logoPicture;

  const style = { backgroundImage: `url(${convertedImage}` };
  return <div className="logo" style={style} />;
};

export default Logo;
